.card-link {
    text-decoration: none;
    color: inherit;
  }
  
  .content-card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .content-image {
    height: 200px;
    object-fit: cover;
  }
  
  .content-card .card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  