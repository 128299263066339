.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Full viewport height */
  background: linear-gradient(135deg, #00008B, #0000FF); /* Gradient background */
  color: white;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}



.hero-info h1 {
  margin: 0;
  font-size: 3rem;
}

.hero-info p {
  font-size: 1.5rem;
  margin: 10px 0;
}

.small-text {
  font-size: 1rem;
  margin-top: 20px;
}
