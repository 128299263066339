/* Basic styles for the card */
.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 8px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Image styling */
  .card img {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
  
  /* Title styling */
  .card-title {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }
  
  /* Button container */
  .card-body {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  /* Buttons */
  .card-body .btn {
    margin-top: 8px;
    width: 100%; /* Ensure buttons are full width */
    text-align: center;
    margin-bottom: 8px;
  }
  