.search-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.search-group .form-control,
.search-group .btn,
.search-group .dropdown {
    margin-right: 10px;
    margin-bottom: 10px;
}

@media (max-width: 576px) {
    .search-group .form-control,
    .search-group .btn,
    .search-group .dropdown {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
