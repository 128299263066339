.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #3629B7; /* Top part color */
  }
  
  .login-card {
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    width: 350px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .lock-image {
    width: 100px; /* Adjust as needed */
    height: 80px; /* Adjust as needed */
    margin: 10px auto;
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid gray;
    border-radius: 5px;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .signup-link {
    color: #007BFF;
    cursor: pointer;
    text-decoration: underline;
  }
  